import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

export default function About() {
  return (
    <Layout page='about' bg='https://s3.amazonaws.com/flyingpoint/miamibg2.jpg'>
      <Helmet>
        <title>About | Flying Point</title>
      </Helmet>
      <h1>About</h1>
      <p>
      Flying Point is a vertically integrated real estate owner and operator specializing in the acquisition, development, leasing, and management of value-add real estate assets in South Florida and New York. Our approach to commercial real estate investing is to build long-term relationships by providing a transparent, reliable and efficient process. With ongoing fluctuations in the markets, we seek to deliver superior risk-adjusted returns across our portfolio.
      </p>
      <h1>Principals</h1>
      <div className='row'>
        <div className='col-half'>
          <h2><b>Sam Genet</b> | Managing Partner</h2>
          <h3>
            Sam Genet is a founder and managing partner of Flying Point. Previously, Sam served as founder and managing partner of SGRE Properties, a full-service real estate owner and operator. At SGRE, he successfully acquired and subsequently sold several value-add real estate assets in New York and South Florida. Through strategic repositioning and redevelopment, he created outsized returns on those assets. Prior to founding SGRE, Sam was vice president of acquisitions at Ashkenazy Acquisition Corporation, where he was a member of the five-person acquisition team that sourced and acquired real estate assets in excess of $500 million.
          </h3>   
        </div>
        <div className='col-half'>
          <h2><b>Zach Sohn</b> | Managing Partner</h2>
          <h3>
            Zach Sohn is a founder and managing partner of Flying Point. Previously, Zach was a real estate executive at H&M, the second largest apparel retailer in the world. At H&M, he secured and negotiated deals for new store, office, and logistics operations and oversaw a portfolio of stores with $1 billion in aggregate annual sales. Prior to H&M, Zach was at RKF (acquired by Newmark in 2018), where he advised institutional and private landlords on retail and office assets as well as tenants on their expansion strategy. Prior to RKF, Zach helped found and significantly grow a division of Ashkenazy Acquisition Corporation focusing on non-traditional revenue generation within their $12 billion real estate portfolio. Zach is deeply involved in several philanthropic causes and sits on the board of directors of Friendship Circle of Miami-Dade County and Jewish Culture High School.
          </h3>      
        </div>
      </div>
    </Layout>
  );
}